<template>
    <div class="product-pages-category">
        <div class="pl-0 pr-0">
            <b-row v-if="isLoading">
                <b-col class="text-center pro-loader" cols="12">
                    <div class="reverse-spinner"></div>
                </b-col>
            </b-row>
            <b-row v-else-if="totalProducts === 0">
                <b-col>
                    <p class="text-center">{{ $t("no_products") }}</p>
                </b-col>
            </b-row>
            <b-row
                class="category-products-grid mx-0"
                id="category-products-grid"
                v-else
            >
                <b-col
                v-for="product in products"
                v-bind:key="product.id"
                class="category-products-item p-0"
                >
                    <ProductCard :product="product" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import ProductCard from "@/esf_utrecht_bankxl/core/components/products/category-product/ProductCard";

export default {
    name: "CategoryComponent",

    components: {
        ProductCard,
    },

    data() {
        return {
            products: [],
            isLoading: true,
        }
    },

    computed: {
        totalProducts() {
            return +this.$store.getters["landingPage/getTotalProducts"];
        },
    },

    mounted() {
      this.getProducts();
    },

    methods: {
        async getProducts() {
            let retval = await this.$store.dispatch("landingPage/getProducts");
            if (retval.status == 200) {
                this.products = retval.data.data.products.items;
                this.isLoading = false;
            }
            this.isLoading = false;
        },
    },
    
}
</script>

<style lang="scss" scoped>
.product-pages-category .pro-loader {
  text-align: center;
  padding: 100px 0px 120px 0px;
  .reverse-spinner {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    border-radius: 50%;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @media screen and (max-width: 560px) {
    padding: 50px 0px;
    .reverse-spinner {
      height: 80px;
      width: 80px;
    }
  }

  .reverse-spinner::before {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    content: "";
    border: 4px solid transparent;
    border-top-color: $primary-gray;
    border-left-color: $primary-gray;
    opacity: 0.7;
    border-radius: 50%;
    -webkit-animation: spinBack 1s linear infinite;
    animation: spinBack 1s linear infinite;
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}
</style>
