<template>
  <section class="landing-page">
    <b-container>
        <Breadcrumbs />
        <div v-html="cmsPageContent.content || ''"></div>
        <CategoryComponent />
        <div v-html="cmsPageContent.secondary_content || ''"></div>
    </b-container>
</section>
</template>

<script>
import CategoryComponent from "@/esf_utrecht_bankxl/core/components/landing-page/CategoryComponent.vue";
import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
import config from "@config";

export default {
    name: "LandingPageCategory",

    components: {
        CategoryComponent,
        Breadcrumbs,
    },

    computed: {
        cmsPageContent() {
            return this.$store.getters["landingPage/getLandingPageData"];
        },
    },

    mounted() {
        const bcrumb = {
            current: this.cmsPageContent.title,
            routes: [],
        };

        this.$store.commit("breadcrumbs/set", bcrumb);
    },

    metaInfo() {
        return {
            meta: [
                {
                name: "title",
                content: this.cmsPageContent
                    ? this.cmsPageContent.meta_title
                    : "test",
                },
                {
                name: "keywords",
                content: this.cmsPageContent
                    ? this.cmsPageContent.meta_keywords
                    : "",
                },
                {
                name: "description",
                content: this.cmsPageContent
                    ? this.cmsPageContent.meta_description
                    : "",
                },
            ],
            title: this.$t("Landing Page"),
            link: [
                {
                rel: "canonical",
                href:
                    config.app_url +
                    (/\/$/.test(this.$route.fullPath)
                    ? this.$route.fullPath
                    : this.$route.fullPath.replace(/^\//, "") + "/"),
                },
            ],
        };
    },
};
</script>
